<template>
  <div class="main-content">
    <loading v-if="Loading"></loading>

    <b-row v-if="!Loading">
      <b-col class="col-sm-4 col-12">
        <b-card :class="{ 'card-item': true }" style="background-color: #96855a; color:black; font-weight: bold;"  >POLSIP</b-card>
        <b-table small :items="tablePolsip" :fields="fields2" show-empty>
          <template v-slot:cell(fullname)="row">
            <div class="display-row" @click="goToDetail(row.item)">
              <img :src="getBadgeSrc(row.item.badge)" height="25px" :class="{ 'glowing-image': row.item.grade > 20 }" />
              <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" style="margin-right: 5px;" /> 
              {{ uppercaseFirstLetter(row.item.fullname) }}
            </div>
          </template>
          <template #empty>
            <div class="text-center">
              Tidak ada 
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-sm-4 col-12">
        <b-card :class="{ 'card-item': true }" style="background-color: #3a6ce0; color:white; font-weight: bold;">POLTAS</b-card>
        <b-table small :items="tablePoltas" :fields="fields2" show-empty>
          <template v-slot:cell(fullname)="row">
            <div class="display-row" @click="goToDetail(row.item)">
              <img :src="getBadgeSrc(row.item.badge)" height="25px" :class="{ 'glowing-image': row.item.grade > 20 }" />
              <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" style="margin-right: 5px;" /> 
              {{ uppercaseFirstLetter(row.item.fullname) }}
            </div>
          </template> 
          <template #empty>
            <div class="text-center">
              Tidak ada 
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col class="col-sm-4 col-12">
        <b-card :class="{ 'card-item': true }" style="background-color: #4a4948; color:white; font-weight: bold;">POLSUS</b-card>
        <b-table small :items="tablePolsus" :fields="fields2" show-empty>
          <template v-slot:cell(fullname)="row">
            <div class="display-row" @click="goToDetail(row.item)">
              <img :src="getBadgeSrc(row.item.badge)" height="25px" :class="{ 'glowing-image': row.item.grade > 20 }"/>
              <img :src="getColorUnit(row.item.police_type)" height="25px" width="3px" style="margin-right: 5px;" /> 
              {{ uppercaseFirstLetter(row.item.fullname) }}
            </div>
          </template>
          <template #empty>
            <div class="text-center">
              Tidak ada 
            </div>
          </template>
        </b-table>
      </b-col>
      
    </b-row>

    <div v-if="LoadingDetail" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from '@/templates/Loading.vue'
export default {
  name: 'Dashboard',
  components: {
    Loading
  },
  data() {
    return {
      Loading : false,
      LoadingDetail : false,
      selfDuty : 0,
      tablePolsip: [],
      tablePoltas: [],
      tablePolsus: [],
      fields: [
        {key:'fullname',label:'Nama Lengkap'},
        {key:'online',label:'Total'},
      ],
      fields2: [
        {key:'fullname',label:'LIST ANGGOTA'},
        {key:'total_on_duty',label:'TOTAL DUTY'},
      ]
    };
  },
  methods: {
    init(){
      this.getData();
    },
    getData() {
      // Get the current date
      let currentDate = new Date();

      // Get the month (zero-based index, so January is 0)
      let currentMonth = currentDate.getMonth() + 1; // Adding 1 to get the correct month

      axios.post("idp/master/user-active/list",{month:currentMonth}).then((response) => {
        this.tablePolsip = response.data.polsip;
        this.tablePoltas = response.data.poltas;
        this.tablePolsus = response.data.polsus;
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    uppercaseFirstLetter(str) {
      // Ubah seluruh string menjadi lowercase
      str = str.toLowerCase();
      // Ambil huruf pertama dan ubah ke uppercase
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handleCardClick(moveto){
      this.Loading = true;

      axios.post("idp/police-on-duty/move",{moveto:moveto}).then((response) => {

      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.getData();
        this.Loading = false;
      });
    },
    getBadgeSrc(path) {
      if(path == 'serpa.jpg'){
        return require('@/assets/images/avatars/serpa.jpg');
      }else if(path == 'serdu.jpg'){
        return require('@/assets/images/avatars/serdu.jpg');
      }else if(path == 'serpol.jpg'){
        return require('@/assets/images/avatars/serpol.jpg');
      }else if(path == 'serka.jpg'){
        return require('@/assets/images/avatars/serka.jpg');
      }else if(path == 'pelma.jpg'){
        return require('@/assets/images/avatars/pelma.jpg');
      }else if(path == 'peldu.jpg'){
        return require('@/assets/images/avatars/peldu.jpg');
      }else if(path == 'letma.jpg'){
        return require('@/assets/images/avatars/letma.jpg');
      }else if(path == 'letdu.jpg'){
        return require('@/assets/images/avatars/letdu.jpg');
      }else if(path == 'kapten.jpg'){
        return require('@/assets/images/avatars/kapten.jpg');
      }else if(path == 'mayor.jpg'){
        return require('@/assets/images/avatars/mayor.jpg');
      }else if(path == 'letkol.jpg'){
        return require('@/assets/images/avatars/letkol.jpg');
      }else if(path == 'kolonel.jpg'){
        return require('@/assets/images/avatars/kolonel.jpg');
      }else if(path == 'serjen.jpg'){
        return require('@/assets/images/avatars/serjen.jpg');
      }else if(path == 'mayjen.jpg'){
        return require('@/assets/images/avatars/mayjen.jpg');
      }else if(path == 'letjen.jpg'){
        return require('@/assets/images/avatars/letjen.jpg');
      }else if(path == 'jendral.jpg'){
        return require('@/assets/images/avatars/jendral.jpg');
      }else if(path == 'kadop.jpg'){
        return require('@/assets/images/avatars/kadop.jpg');
      }else if(path == 'sekertaris.jpg'){
        return require('@/assets/images/avatars/sekertaris.jpg');
      }
    },
    getColorUnit(police_type) {
      if(police_type == '1'){
        return require('@/assets/images/avatars/polsip.jpg');
      }else if(police_type == '3'){
        return require('@/assets/images/avatars/polmas.jpg');
      }else if(police_type == '2'){
        return require('@/assets/images/avatars/polsus.jpg');
      }else{
        return require('@/assets/images/avatars/golden.jpg');
      }
    },
    goToDetail(data){
      var steamhex = 'steam:'+data.steamhex;
      this.LoadingDetail = true;
      axios.post("idp/user/get-id",{identifier:steamhex}).then((response) => {
        if(response.data.status == 2000){
          this.$router.push({ path: "/idp-warga-detail/"+response.data.data.id });
        }
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.LoadingDetail = false;
        this.modalShow = false;
      });
    },
  },
  mounted(){
    this.init();
  }
}
</script>
<style scoped>

.card-item {
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.display-row {
  display: flex;
  align-items: center;
  transition: transform 0.1s;
}

.display-row:hover {
  transform: scale(1.2) translateX(8%);
  cursor: pointer;
  animation: bounce 0.3s;
  /* box-shadow: 0 0 15px rgba(0, 255, 0, 0.6); */
  filter: brightness(2.2);
  color:#D4AF37;
}

@keyframes glowZoom {
    0% {
        transform: scale(1);
        filter: brightness(1);
    }
    50% {
        transform: scale(1.1); /* Zoom sedikit */
        filter: brightness(1.4); /* Efek pendar */
    }
    100% {
        transform: scale(1);
        filter: brightness(1);
    }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Kelas untuk gambar dengan efek glowing dan zoom */
.glowing-image {
    animation: glowZoom 1.3s infinite; /* Durasi dan infinite loop */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* pastikan overlay di atas konten lain */
}

.loading-spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 8px solid #fff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
</style>